/* Mixins */
.project-select-component .group-item {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.6)), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 20px;
}
.project-select-component .group-item .app-item {
  margin-bottom: 40px;
}
.project-select-component .group-item .group-title {
  color: #272727;
  font-size: 16px;
  position: relative;
  padding-left: 10px;
  margin-bottom: 18px;
}
.project-select-component .group-item .group-title::before {
  position: absolute;
  content: "";
  width: 4px;
  height: 16px;
  background: #005ABB;
  left: 0;
  top: 3px;
}
.project-select-component .group-item .project-item-wrapper {
  display: inline-block;
  vertical-align: top;
}
.project-select-component .group-item .project-item-wrapper .project-item-card {
  width: calc(25% - 32px);
  min-width: 346px;
  min-height: 115px;
  padding: 12px 14px;
  line-height: 1.5;
  border-radius: 4px;
  margin: 0 16px 16px 0;
  border: 1px solid #E5E7EC;
}
.project-select-component .group-item .project-item-wrapper .project-item-card .project-item-img {
  width: 52px;
  height: 52px;
  margin-right: 16px;
}
.project-select-component .group-item .project-item-wrapper .project-item-card .app-text-wrapper {
  line-height: 1.5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}
.project-select-component .group-item .project-item-wrapper .project-item-card .app-title {
  font-size: 16px;
  color: #272727;
  margin-bottom: 4px;
}
.project-select-component .group-item .project-item-wrapper .project-item-card .version-text {
  font-size: 14px;
  color: #666;
}